// extracted by mini-css-extract-plugin
export const container = "about-module--container--bIZcy";
export const img = "about-module--img--UMLoO";
export const introductionContainer = "about-module--introductionContainer--3ebdg";
export const miniTitle = "about-module--miniTitle--3z1oJ";
export const number = "about-module--number--3aRE0";
export const adress = "about-module--adress--1PK9S";
export const adressContainer = "about-module--adressContainer--kUF9B";
export const photoContainer = "about-module--photoContainer--2oFl2";
export const button = "about-module--button--3piPb";
export const tableContainer = "about-module--tableContainer--3dFgK";
export const rowContainer = "about-module--rowContainer--2M2V6";