// extracted by mini-css-extract-plugin
export const cutBackground = "menu-module--cutBackground--2ZoBR";
export const colorBackground = "menu-module--colorBackground--3FbcC";
export const permBackground = "menu-module--permBackground--1o93f";
export const treatmentBackground = "menu-module--treatmentBackground--1ui0P";
export const othersBackground = "menu-module--othersBackground--3sRsF";
export const setBackground = "menu-module--setBackground--qEun3";
export const container = "menu-module--container--15oTi";
export const table = "menu-module--table--9eSWY";
export const keyName = "menu-module--keyName--1W2x4";
export const content = "menu-module--content--qc2rs";
export const option = "menu-module--option--3KtXF";
export const miniTitle = "menu-module--miniTitle--3pUSe";
export const clickArea = "menu-module--clickArea--WTddH";
export const plusIcon = "menu-module--plusIcon--2OL5f";
export const iconOpen = "menu-module--iconOpen--3rE8A";
export const contentsContainerDom = "menu-module--contentsContainerDom--3n_vJ";
export const contentsContainerDom_Opened = "menu-module--contentsContainerDom_Opened--mcDhs";
export const contentsDom = "menu-module--contentsDom--Ny2Mh";
export const contentsDom_Opened = "menu-module--contentsDom_Opened--23GF-";
export const show = "menu-module--show--19HW6";
export const contentsDom_Closed = "menu-module--contentsDom_Closed--1G64Z";
export const out = "menu-module--out--EEmTv";
export const lines = "menu-module--lines--182SB";