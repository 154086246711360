// extracted by mini-css-extract-plugin
export const container = "payment-module--container--3lwkI";
export const introduction = "payment-module--introduction--3-sy_";
export const paymentTable = "payment-module--paymentTable--1S9Oy";
export const paymentTablePc = "payment-module--paymentTablePc--1wXuL";
export const tableRow = "payment-module--tableRow--3atQ4";
export const tableData = "payment-module--tableData--3ezNu";
export const icon = "payment-module--icon--1fnSL";
export const paymentName = "payment-module--paymentName--3O9tf";
export const transImg = "payment-module--transImg--2wbJc";
export const transTitle = "payment-module--transTitle--2DOlM";
export const tableDataPc = "payment-module--tableDataPc--1xGNg";
export const transContainer = "payment-module--transContainer--32MVE";